(function($){
    'use strict';

    var 
    	$headerToggle = $('.js-header-toggle button'),
        $headerTabbingElements = $('.js-header-taborder'),
    	$navigation = $('.js-nav'),
    	$navigationToggle = $navigation.find('.js-nav-toggle'),
        $navigationLink = $navigation.find('.js-nav-link'),
        $navigationLv2 = $navigation.find('.js-nav-lvl2'),
        navigationSpaceState = true;

    $(window).keydown(function(event){
        if(event.keyCode == 27){
            $navigationLink.removeClass('open');
            $navigationLv2.removeClass('open');
        }
    });

    $navigationToggle.each(function() {
    	var $toggleButton = $(this),
    		$subNavigation = $toggleButton.closest('li').find('.js-nav-lvl2');

    	if($subNavigation.length) {
	    	$toggleButton.closest('div').addClass('parent');
	    	
	    	$toggleButton.click(function() {
		        if($toggleButton.hasClass('active')) {
		            $toggleButton.removeClass('active');
		            $subNavigation.slideUp(300, function() {
		                $subNavigation.removeAttr('style').removeClass('active');
		            });
		        } else {
		            $toggleButton.addClass('active');
		            $subNavigation.slideDown(300, function() {
		                $subNavigation.addClass('active').removeAttr('style');
		            });
		        }

	            return false;
		    });
    	} else {
    		$toggleButton.remove();
    	}
    });

    $navigationLink.each(function() {
    	var $link = $(this),
    		$subNavigation = $link.closest('li').find('.js-nav-lvl2');

        $link.keyup(function(evt) {// Open/Close level 2 navigation when using keyboard

	        if(!$headerToggle.is(':visible')) {
                if (evt.keyCode == 32 || evt.keyCode == 13) {
                    navigationSpaceState = false;
                }
            }
        }).keydown(function(evt) {

            if(!$headerToggle.is(':visible')) {
                if (evt.keyCode == 32 || evt.keyCode == 13) {
                    navigationSpaceState = true;
                    
                    if($link.hasClass('open')) {
                        $link.removeClass('open');
                        $subNavigation.removeClass('open');
                    } else {
                    	$navigation.find('.open').removeClass('open');
                        $link.addClass('open');
                        $subNavigation.addClass('open');
                    }

                    return false;
                }
            }
        }).on('touchstart', function(){ // Open/Close level 2 navigation on large touch devices
            if(!$headerToggle.is(':visible')) {
                if($link.hasClass('open')) {
                    $link.removeClass('open');
                    $subNavigation.removeClass('open');
                } else {
                    $link.addClass('open');
                    $subNavigation.addClass('open');
                }

                return false;
            }
         });
    });



    /* Mobile Navigation behaviours */

    var $primaryNav = $('.js-nav-primary');

    $headerToggle.click(function() {
    	if($headerToggle.hasClass('active')) {
    		closeNavigation();
    	} else {
    		openNavigation();
    	}

    	return false;
    });

    function openNavigation() {
        $('.js-header-toggle').find('button').addClass('active');
        $primaryNav.addClass('active').on('click', stopNavigation);
        $headerTabbingElements.find('a, button').attr('tabindex', '-1');
    }

    function closeNavigation() {
        $('.js-header-toggle').find('button').removeClass('active');
		$primaryNav.removeClass('active').off('click', stopNavigation); 
        $headerTabbingElements.find('a, button').removeAttr('tabindex');
    }

    function stopNavigation(e) {
    	e.stopPropagation();
    }
    
}(jQuery));