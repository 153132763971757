(function($){
    'use strict';

    var $subNav = $('.js-sub-nav');

        $subNav.each(function(){

            var $subNavWrap = $(this);
           
            $subNavWrap.find(".sub-nav__parent").each(function(){

                var $parentLI = $(this);
                
                if ($parentLI.has('ul').length == 0){
                    $parentLI.removeClass('sub-nav__parent');
                } else {

                    $parentLI.find('button').click(function () {
                        if (!$parentLI.hasClass('current')) {
                            
                            $parentLI.find('ul').slideDown('fast');
                            $parentLI.addClass('current');

                        } else {
                            $parentLI.find('ul').slideUp('fast');
                            $parentLI.removeClass('current');
                        }
                    });
                }
            });
        }); 

}(jQuery)); 