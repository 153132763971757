/*global Cookies*/

(function($){
    'use strict';

    var $userAccept = $('.js-accept'),
        $userButtonWrapper = $('.js-user-set'),
        userHasAccepted = Cookies.get('wcc-accepted');

        if($userAccept.length){

            if(userHasAccepted == undefined && !$userAccept.is(':visible')){
                setTimeout(function(){
                    $userAccept.slideDown(500);
                }, 1000);
            }
        
            $userAccept.find('.site-note__accept').click(function(event){
                
                event.preventDefault();

                if(!$userAccept.hasClass('accepted')){
                    $userAccept.addClass('accepted');
                    $userAccept.slideUp('fast');
                    
                    var $curUserActive = $userButtonWrapper.find('.user-buttons__type.active'),
                        curUserType = $curUserActive.attr('data-user-type');
                        Cookies.set('wcc-accepted', 'true', { expires: 1 });

                        if(curUserType){
                            Cookies.set('wcc-user-type', curUserType, { expires: 1 });
                        }else {
                            Cookies.set('wcc-user-type', 'visitor', { expires: 1 });
                            $userButtonWrapper.find('a[data-user-type="visitor"]').addClass('active');
                        }
                }

            });
        }

}(jQuery));