$(function($) {
    'use strict'

    $("span.emergency-level:contains('Emergency')").each(function(){
        $(this).addClass('alerts-list__alerts-red'); 
        $(this).parents('.alerts-list__item').addClass('alerts-list__alerts-border-red');        
    })

    $("span.emergency-level:contains('Alert')").each(function(){
        $(this).addClass('alerts-list__alerts-yellow'); 
        $(this).parents('.alerts-list__item').addClass('alerts-list__alerts-border-yellow');
    })

    $("span.emergency-level:contains('Notice')").each(function(){
        $(this).addClass('alerts-list__alerts-green');
        $(this).parents('.alerts-list__item').addClass('alerts-list__alerts-border-green');         
    })   
}(jQuery)); 