/*global slick*/

(function($){
    'use strict';

    var $imgslider = $('.js-img-slider'),
        $caption = $imgslider.find('.img-slider__caption');

    if($imgslider.length){

        $imgslider.each(function(){

            var $carousel = $(this);
            var $wrapper = $carousel.find('.img-slider__slideouter');

            $wrapper.on('init', function(){
                $carousel.find('.js-slider-pause').click(function(){
                    playPause($wrapper, $(this));
                    return false;
                });
            });
            
            $wrapper.slick({
                autoplay: true,
                autoplaySpeed: 2000,
                arrows: true,
                dots: false,
                accessibility: true,
                mobileFirst: true,
                infinite: true,
                fade: true,
                nextArrow: $carousel.find('.js-img-slider-next'),
                prevArrow: $carousel.find('.js-img-slider-prev'),
                cssEase: 'linear'
            });

            $wrapper.on('beforeChange', function(event, slick, currentSlide, nextSlide){
                var caption = $wrapper.find('.img-slider__slide').eq(nextSlide).attr('data-caption')
                $caption.text(caption)
            });

        });

        function playPause($carousel, $toggle) {
            if ($toggle.hasClass('active') ) {
                $carousel.slick('slickPlay');
                $toggle.removeClass('active');
            } else {
                $carousel.slick('slickPause');
                $toggle.addClass('active');
            }
        }
    }


}(jQuery));