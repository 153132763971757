(function($){
    
    'use strict';

    var $logoSlider = $('.js-logo-slider');

        if($logoSlider.length){

            $logoSlider.each(function(){

                var $wrapper = $logoSlider.find('.logo-carousel__wrapper');

                $wrapper.slick({
                    autoplay: true,
                    autoplaySpeed: 6000,
                    arrows: false,
                    dots: false,
                    infinite: true,
                    mobileFirst: true,
                    speed: 600,
                    swipe: true,
                    slidesToShow: 5,
                    slidesToScroll: 1,
                    responsive: [
                        {
                            breakpoint: 1199,
                            settings: {
                                slidesToShow: 5,
                                slidesToScroll: 1,
                                infinite: true,
                            }
                        },
                        {
                            breakpoint: 600,
                            settings: {
                                slidesToShow: 4,
                                slidesToScroll: 1
                            }
                        },
                        {
                            breakpoint: 100,
                            settings: {
                                slidesToShow: 1,
                                slidesToScroll: 1
                            }
                        }
                    ]
                }); 

            });
        }

}(jQuery));
