(function($){
    'use strict';

    $('.js-list-result-text').each(function() {
        var $listResultText = $(this),
            $listResultButton = $listResultText.siblings('button');

        $listResultButton.click(function() {

            if($listResultButton.hasClass('active')) {
                $listResultButton.removeClass('active');
                $listResultButton.find('span').text('More');
                $listResultText.slideUp(300, function() {
                    $listResultText.removeClass('active').removeAttr('style');
                });
            } else {
                $listResultButton.addClass('active');
                $listResultButton.find('span').text('Less');
                $listResultText.slideDown(300, function() {
                    $listResultText.addClass('active').removeAttr('style');
                });
            }

            return false;
        });
    });

}(jQuery));
