/*global google*/
(function ($) {
    'use strict';

    var $dateWrap = $('.js-date-input'),
        $search = $('.js-search'),
        $autocomplete,
        submitPostponed = false,
        continueSubmit = false;

    if ($dateWrap.length) {
        $dateWrap.each(function () {
            var $dateWrapItem = $(this).find('input');

            $dateWrapItem.keydown(function (event) {
                event.preventDefault();
                return false;
            });

            $dateWrapItem.datepicker({
                dateFormat: "dd-mm-yy"
            });

            $dateWrapItem.closest('form').submit(function() {
                var dateWrapItemName = $dateWrapItem.attr('name'),
                    dateWrapItemDate = $dateWrapItem.val(),
                    dateWrapItemDateArray = dateWrapItemDate.split('-');

                if(dateWrapItemDateArray.length > 1) {
                    dateWrapItemDate = dateWrapItemDateArray[2] + '-' + dateWrapItemDateArray[1] + '-' + dateWrapItemDateArray[0];
                }

                if(dateWrapItemName.indexOf('-format') !== -1) {
                    $dateWrapItem.siblings('input[type="hidden"]').val(dateWrapItemDate);

                } else {
                    $dateWrapItem.after('<input type="hidden" name="' + dateWrapItemName + '" value="' + dateWrapItemDate + '">');
                    $dateWrapItem.attr('name', dateWrapItemName + '-format');                    
                }
            });
        });
    }

    var $inputReset = $('.js-input-reset'),
        $clearFieldParents = $('.js-clear-field');

    $inputReset.find('.clear-button').on('click', function () {
        $clearFieldParents.find('.form__input--date').datepicker('setDate', null);
        $clearFieldParents.find('select').find('option:eq(0)').prop('selected', true);
        $clearFieldParents.find('input').not('.form__input--date').val("");
    });

    /* ========= Auto Complete Function ========= */

    function escapeRegExp(str) {
        return str.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&');
    }


	function debounce(func, wait) {
	    var timeout;
	    return function() {
	        var context = this, args = arguments;
	        clearTimeout(timeout);
	        timeout = setTimeout(function() {
	            timeout = null;
	            func.apply(context, args);
	        }, wait);
	    };
	}

    function activateSearch($search) {
        var searchPartialBaseUrl = $search.attr('data-partial-url'),
            searchPartialResults = $search.attr('data-partial-results'),
            collection = $search.attr('data-partial-collection'),
            $searchInput = $search.find('.js-search-input'),
            $searchPredictiveList = $search.find('.js-search-list'),
            searchInputValue = '',
            $publicationsSort = $('#publications-sort');

        $search.focusout(function () {
            setTimeout(function () {
                var $focused = $search.find(':focus');

                if (!$focused.length) {
                    $searchPredictiveList.fadeOut(300);
                }
            }, 100); //delay to wait until other element recieved focus
        });

        $searchInput.focusin(function () {
            $searchPredictiveList.fadeIn(300);
        }).keydown(function (e) {
            if (e.keyCode === 40) { //keydown
                if ($searchPredictiveList.find('a').length) {
                    $searchPredictiveList.find('li:first-child a').focus();
                }
            }
        }).keyup(debounce(function () {
            var searchValue = $searchInput.val(),
                searchQuery = searchValue.toLowerCase(),
                searchPartialUrl = searchPartialBaseUrl +
                '?partial_query=' + encodeURIComponent(searchValue) +
                '&show=' + searchPartialResults + '&collection=' + collection;

            if ($publicationsSort.length) {
                $publicationsSort.val('');
            }

            if (searchInputValue !== searchValue) {
                searchInputValue = $.parseJSON(JSON.stringify(searchValue)); //cloning to break the reference

                if (searchQuery.length) {
                    $.get(searchPartialUrl, function (data) {
                        $searchPredictiveList.empty();
                        var searchQueryRegex = new RegExp('(' + escapeRegExp(searchQuery) + ')', 'gi'),
                            $searchPredictiveListHtml = '<ul>\n';

                        data = $.parseJSON(JSON.stringify(data));

                        $.each(data, function (key, item) {
                            var searchPredictiveItem = item.disp,
                                searchPredictiveItemHtml = searchPredictiveItem.replace(
                                    searchQueryRegex,
                                    '<span>$1</span>'
                                );
                            $searchPredictiveListHtml += '<li><a href="#">' + searchPredictiveItemHtml + '</a></li>\n';
                        });

                        $searchPredictiveListHtml += '</ul>\n';

                        $searchPredictiveList.html($searchPredictiveListHtml);

                        $searchPredictiveList.find('a').focusin(function () {
                            $searchInput.val($(this).text());
                        }).keydown(function (e) {
                            if (e.keyCode === 40) { //arrow down
                                var $next = $(this).parent('li').next();

                                if ($next.length) {
                                    $next.find('a').focus();
                                    e.preventDefault();
                                }
                            } else if (e.keyCode === 38) { //arrow up
                                var $prev = $(this).parent('li').prev();

                                if ($prev.length) {
                                    $prev.find('a').focus();
                                    e.preventDefault();
                                }
                            }
                        }).click(function (e) {
                            e.preventDefault();
                            $searchInput.val($(this).text());
                            $searchInput.closest('form').submit();
                            return false;
                        });
                    });
                } else {
                    $searchPredictiveList.empty();
                }
            }
        }, 250));
    }

    if ($search.length) {
        $search.each(function () {
            activateSearch($(this));
        });
    }

    /* ==================================================== */

    $(document).ready(function () {
        validateFormFields();
    });


    function validateFormFields() {

        var $form = $('.js-form-validate');

        $.validator.addMethod('fieldReq', $.validator.methods.required, 'This field is required.');
        $.validator.addMethod('emailReq', $.validator.methods.required, 'Please enter an email.');
        $.validator.addMethod('emailFormat', $.validator.methods.email, 'Please enter a valid email.');
        $.validator.addMethod('selectReq', function (value) {

            if (value == "default") {
                return false;
            } else {
                return true;
            }

        }, 'Please select an option.');

        $.validator.addMethod('checkboxGroupReq', function (value) {
            if (value == undefined) {
                return false;
            } else {
                return true;
            }

        }, 'Please select an option.');

        $.validator.addMethod('radioGroupReq', function (value) {
            if (value == undefined) {
                return false;
            } else {
                return true;
            }

        }, 'Please select an option.');

        $.validator.addClassRules('js-validation-field', {
            fieldReq: true,
        });

        $.validator.addClassRules('js-validation-select', {
            selectReq: true,
        });

        $.validator.addClassRules('js-validation-email', {
            emailReq: true,
            emailFormat: true,
        });

        $.validator.addClassRules('js-validation-checkbox', {
            checkboxGroupReq: true,
        })

        $.validator.addClassRules('js-validation-radio', {
            radioGroupReq: true,
        })

        $.validator.setDefaults({
            ignore: '',
            errorPlacement: function (error, element) {

                var $errorLabel;
                if ($(element).hasClass('js-validation-checkbox') || $(element).hasClass('js-validation-radio')) {
                    var inputWrapSelector = ($(element).hasClass('js-validation-checkbox')) ? '.form__checkbox-outer' : '.form__radio-outer';
                    $errorLabel = $(element).closest(inputWrapSelector).find('.form__error');
                } else {
                    $errorLabel = $(element).siblings('.form__error');
                }

                $errorLabel.html(error.text());
                $errorLabel.addClass('active');
            },
            highlight: function (element) {
                if ($(element).hasClass('js-validation-checkbox') || $(element).hasClass('js-validation-radio')) {
                    var inputWrapSelector = ($(element).hasClass('js-validation-checkbox')) ? '.form__checkbox-outer' : '.form__radio-outer';
                    $(element).closest(inputWrapSelector).find('.form__error').addClass('active');
                } else {
                    $(element).siblings('.form__error').addClass('active');
                }
            },
            unhighlight: function (element) {
                if ($(element).hasClass('js-validation-checkbox') || $(element).hasClass('js-validation-radio')) {
                    var inputWrapSelector = ($(element).hasClass('js-validation-checkbox')) ? '.form__checkbox-outer' : '.form__radio-outer';
                    $(element).closest(inputWrapSelector).find('.form__error').removeClass('active');
                } else {
                    $(element).siblings('.form__error').removeClass('active');
                }
            }
        });

        $form.each(function () {
            var $cur = $(this);
            $cur.validate();
        });


    }

    $(document).ready(function () {
        initialiseGoogleMapsAutocomplete();
    });

    function initialiseGoogleMapsAutocomplete() {
        var $_input = $("#map_address_input")[0],
            $_form = $("#map-address-form")[0],
            $locatorBtn = $($('#maps-address-locator')[0]);

        if ($_input && $_form) {
            $($_form).on("submit", updateGoogleMapInputs);

            $autocomplete = new google.maps.places.Autocomplete($_input);
            $autocomplete.addListener('place_changed', updateCoords);
        }

        if ($locatorBtn) {
            $locatorBtn.on("click", populateLocation);
        }
    }

    function updateCoords() {
        var $place = $autocomplete.getPlace(),
            $lat = $($("#map_address_lat")[0]),
            $lon = $($("#map_address_lon")[0]);

        if ($place !== undefined) {
            $lat.val($place.geometry.location.lat);
            $lon.val($place.geometry.location.lng);
        }
        if (continueSubmit || submitPostponed && $place) {
            var $_form = $("#map-address-form")[0];
            $_form.submit();
        }
    }

    function updateGoogleMapInputs(e) {
        e.preventDefault();
        submitPostponed = true;

        updateCoords();

        continueSubmit = true;
    }

    function populateLocation(e) {
        e.preventDefault();

        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(updateAddressAndCoords, showNote);
        }
    }

    function showNote() {

        if (!$('.location-map__location-note:not(.location-map__location-error)').is(':visible')) {
            $('.location-map__location-note:not(.location-map__location-error)').slideDown('fast');

            setTimeout(function () {
                $('.location-map__location-note:not(.location-map__location-error)').slideUp('fast');
            }, 7000);
        }
    }

    function showError() {

        if (!$('.location-map__location-error').is(':visible')) {
            $('.location-map__location-error').slideDown('fast');

            setTimeout(function () {
                $('.location-map__location-error').slideUp('fast');
            }, 7000);
        }
    }

    function updateAddressAndCoords(position) {
        var $input = $($("#map_address_input")[0]),
            $lat = $($("#map_address_lat")[0]),
            $lon = $($("#map_address_lon")[0]),
            $_form = $("#map-address-form")[0],
            geocoder = new google.maps.Geocoder;

        $lat.val(position.coords.latitude);
        $lon.val(position.coords.longitude);
        var latlng = {
            lat: parseFloat(position.coords.latitude),
            lng: parseFloat(position.coords.longitude)
        };

        geocoder.geocode({
            'location': latlng
        }, function (results, status) {
            if (status === 'OK') {
                if (results[0]) {
                    $input.val(results[0].formatted_address);
                    $_form.submit();
                } else {
                    showError();
                }
            } else {
                console.error('Geocoder failed due to: ' + status);
                showError();
            }
        });
    }

}(jQuery));