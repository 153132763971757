(function ($) {
    'use strict';

    var $alertClose = $('.js-alert-close');

    $alertClose.each(function(){

        var $cur = $(this);

        $cur.find('.alert__close').click(function(){
            if($alertClose.is(':visible')){
                $alertClose.slideUp('fast');
            }
        });
    });
}(jQuery));