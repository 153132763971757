(function($){
    'use strict';

    var $container = $('.js-search-container'),
        $searchToggle = $container.find('.user-buttons__search-toggle');

    $searchToggle.click(function(){
        if(!$container.hasClass('search-active')){
            $container.addClass('search-active');
        }else {
            $container.removeClass('search-active');
        }
    });

 
}(jQuery));