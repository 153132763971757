/* (function($){
    'use strict';

    var $branchWrap = $('.js-branch-map');

    if($branchWrap.length){
        
        $branchWrap.each(function(){
            
            var $cur = $(this),
                $map = $cur.find('.branch-locator__map-outer'),
                sydney = { lat: -34.4278, lng: 150.8931 },
                mapZoom = 13;

                initGoogleMap($map[0], sydney, mapZoom, false);
        }); 

    }

    function initGoogleMap($jsMapSelector, mapPosition, mapZoom, mapPin, address) {

	    var map = new google.maps.Map($jsMapSelector, {zoom: mapZoom, center: mapPosition });
	    
	    if(mapPin){
	        var marker = new google.maps.Marker({
	            position: mapPin, 
	            map: map,
	        });

	        if(address){
	            marker.addListener('click', function() {
	                window.open('https://maps.google.com/?q=' + address, '_blank');
	            });
	        }
	    }
	}

}(jQuery)); */ 