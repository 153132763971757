(function($){
    'use strict';

    $('.search-blade__item button').each(function() {
		var $searchButton = $(this);

		$searchButton.click(function() {
			var $searchBlade = $searchButton.closest('.search-blade'),
				$activeInput = $searchBlade.find('.search-blade__tab.active input[type="text"]'),
				$searchItem = $searchButton.closest('.search-blade__item'),
				$searchTab = $searchBlade.find('.search-blade__tab[data-tab="' + $searchItem.attr('data-tab') + '"]'),
				$searchInput = $searchTab.find('input[type="text"]');

			if(!$searchItem.hasClass('active')) {
				$searchInput.val($activeInput.val());
				$searchBlade.find('.active').removeClass('active');
				$searchItem.addClass('active');
				$searchTab.addClass('active');
			} 

			return false;
		});
	});
    
    

}(jQuery));
