/*global google*/

(function($){
    'use strict';

    var $pageWrap = $('.js-page-map');

    if($pageWrap.length){
        
        $pageWrap.each(function(){
            
            var $cur = $(this),
                $map = $cur.find('.google-map__map'),
                mapAddress = $cur.attr('data-address'),
                mapLat = $cur.attr('data-lat'),
                mapLng = $cur.attr('data-lng'),
                mapZoom = $cur.attr('data-zoom'),
                pinLat = $cur.attr('data-pin-lat'),
                pinLng = $cur.attr('data-pin-lng');

                if(mapLat && mapLng && mapZoom){
                
                    mapLat = parseFloat(mapLat);
                    mapLng = parseFloat(mapLng);
                    mapZoom = parseFloat(mapZoom);
                    
                    var location = { lat: mapLat, lng: mapLng };

                    var pin = false;

                    if(pinLat && pinLng){

                        pinLat = parseFloat(pinLat);
                        pinLng = parseFloat(pinLng);

                        pin = {
                            lat: pinLat,
                            lng: pinLng,
                        }

                    }

                    initGoogleMap($map[0], location, mapZoom, pin, mapAddress);

                }

        }); 

    }

	function initGoogleMap($jsMapSelector, mapPosition, mapZoom, mapPin, address) {

	    var map = new google.maps.Map($jsMapSelector, {zoom: mapZoom, center: mapPosition });
	    
	    if(mapPin){
	        var marker = new google.maps.Marker({
	            position: mapPin, 
	            map: map,
	        });

	        if(address){
	            marker.addListener('click', function() {
	                window.open('https://maps.google.com/?q=' + address, '_blank');
	            });
	        }
	    }
	}

}(jQuery));