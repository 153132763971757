(function($){
    'use strict';
    
    var $carouselSlider = $('.js-banner-carousel');

    if($carouselSlider.length){

        $carouselSlider.each(function() {
            var $cur = $(this),
                $carouselWrapper = $cur.find('.banner-carousel__wrapper'),
                $slideCaption = $cur.find('.banner-carousel__caption'),
                $prevArrow = $cur.find('.banner-carousel__prev'),
                $nextArrow = $cur.find('.banner-carousel__next');

                $carouselWrapper.on('init', function(){
                    $cur.find('.js-banner-pause').click(function(){
                        playPauseCarousel($carouselWrapper, $(this));
                        return false;
                    });
                });

                $cur.find('.banner-carousel__wrapper').slick({
                    autoplay: true,
                    autoplaySpeed: 6000,
                    arrows: true,
                    prevArrow: $prevArrow,
                    nextArrow: $nextArrow,
                    dots: false,
                    infinite: true,
                    fade: true,
                    mobileFirst: true,
                    speed: 600,
                    swipe: false,
                });

                insertBannerCaption($carouselWrapper, $slideCaption, 0);
                
                $carouselWrapper.on('beforeChange', function(event, slick, currentSlide, nextSlide){
                    insertBannerCaption($carouselWrapper, $slideCaption, nextSlide);
                });

        });

        function playPauseCarousel($carouselSlider, $sliderToggle) {
            if ($sliderToggle.hasClass('active') ) {
                $carouselSlider.slick('slickPlay');
                $sliderToggle.removeClass('active');
            } else {
                $carouselSlider.slick('slickPause');
                $sliderToggle.addClass('active');
            }
        }

        function insertBannerCaption($carouselWrapper, $captionWrapper, slideIndex){
            var caption = $carouselWrapper.find('.banner-carousel__item').eq(slideIndex).attr('data-caption'),
            pageUrl = $carouselWrapper.find('.banner-carousel__item').eq(slideIndex).attr('data-page-url');

            $captionWrapper.text('');
            $captionWrapper.children().remove();

            if(pageUrl.length != 0){
                var pageUrl = decodeURIComponent(pageUrl),
                    $pageLink = $('<a>', { "href" : pageUrl, "text" : caption });
                $captionWrapper.append($pageLink);
            }else {
                $captionWrapper.text(caption);
                
            }

        }

    }

}(jQuery));
