(function($){
    'use strict';

    $('.js-accordion-title button').each(function() {
		var $accordionToggle = $(this);

		$accordionToggle.click(function() {
			var $accordionTitle = $(this).closest('.js-accordion-title'),
				$accordionText = $accordionTitle.siblings('.js-accordion-text');

			if($accordionTitle.hasClass('active')) {
				$accordionTitle.removeClass('active');
				$accordionText.slideUp(300, function() {
					$accordionText.removeClass('active').removeAttr('style');
				});
			} else {
				$accordionTitle.addClass('active');
				$accordionText.slideDown(300, function() {
					$accordionText.addClass('active').removeAttr('style');
				});
			}

			return false;
		});
	});
}(jQuery));