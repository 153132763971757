/*global google*/
/*global OverlappingMarkerSpiderfier*/
/*global MarkerClusterer*/
/*global defaultFilters*/

(function ($) {

    'use strict';

    var $locationMap = $('.location-map__map').eq(0),
        markerCluster,
        markerList = {
            "arts": [],
            "beaches": [],
            "carparks": [],
            "communities": [],
            "events": [],
            "libraries": [],
            "parks": [],
            "playgrounds": [],
            "skate": [],
            "sports": [],
            "works": [],
            "cycleways": [],
            "dogs": []
        },
        catList = [
            "arts",
            "beaches",
            "carparks",
            "communities",
            "events",
            "libraries",
            "parks",
            "playgrounds",
            "skate",
            "sports",
            "works",
            "cycleways",
            "dogs"
        ],
        defaultFilterList;

    if ($locationMap.length) {

        defaultFilterList = getDefaultListArray();

        var params = window.location.href.includes("?") ? window.location.href.split("?")[1].split("&") : "",
            locationData = {};

        for (var i = 0; i < params.length; i++) {
            var paramName = params[i].split("=")[0],
                paramData = params[i].split("=")[1];

            locationData[paramName] = paramData;
        }

        var address = locationData.map_address_input ? decodeURIComponent(locationData.map_address_input.replace(/\+/g,"%20")) : false,
            latitude = locationData.map_address_lat ? parseFloat(locationData.map_address_lat) : false,
            longitude = locationData.map_address_lon ? parseFloat(locationData.map_address_lon) : false,
            markers = new google.maps.Data(),
            cycleways = new google.maps.Data(),
            dogAreas = new google.maps.Data(),
            infowindow = new google.maps.InfoWindow(),
            map = new google.maps.Map($locationMap[0], {
                zoom: 14.71,
                fullscreenControl: false,
                mapTypeControl: false,
                streetViewControl: false,
                center: {
                    lat: latitude ? latitude : -34.4271756,
                    lng: longitude ? longitude : 150.8898443
                }
            }),
            oms = new OverlappingMarkerSpiderfier(map, { 
                markersWontMove: true,   // we promise not to move any markers, allowing optimizations
                markersWontHide: true,   // we promise not to change visibility of any markers, allowing optimizations
                basicFormatEvents: true  // allow the library to skip calculating advanced formatting information
            });

        if ($locationMap.length) {
            plotData(map, oms);
            autoCompleteInit(map);
            showUserLocation();
            filterButtonInit();
            reduceFilterListInit();
            toggleFilterListInit();
        }

        if (address != false && latitude != false && longitude != false) {
            var location = {
                    lat: latitude,
                    lng: longitude
                },
                locationMarker = new google.maps.Marker({
                    position: location,
                    map: map,
                    animation: google.maps.Animation.DROP,
                    icon: 'https://wollongong.nsw.gov.au/_nocache?a=3838:static/mysource_files/icon-map-locate.png'
                });

            $($("#location-map__search")[0]).val(address);
        }
    
    }

    /*
        Plot the data values from the selected JSON sources
    */

    function plotData(map, oms) {

        var markerSrc = '//wollongong.nsw.gov.au/__data/assets/text_file/0029/9794/wolcc_geojson.json',
            cycleWaySrc = '//wollongong.nsw.gov.au/_integration/google-maps-geojson-file-endoints/wolcc_geojson_cycleways.json',
            dogAreaSrc = '//wollongong.nsw.gov.au/_integration/google-maps-geojson-file-endoints/wolcc_geojson_dogsofleashareas.json';

        // var markerSrc = '../mysource_files/data.json',
        //     cycleWaySrc = '../mysource_files/data-cycle.json',
        //     dogAreaSrc = '../mysource_files/data-dogarea.json';

        markers.loadGeoJson(markerSrc, null, function (features) {

            markers = features.map(function (feature) {

                var g = feature.getGeometry(),
                    category = feature.getProperty('category'),
                    icon = getMarkerIcon(category);

                var marker = new google.maps.Marker({
                    'position': g.get(0),
                    'icon': icon,
                    animation: google.maps.Animation.DROP,
                });

                addToMarkerList(category, marker, markerList);

                marker.addListener('spider_click', function (event) {
                    var html = "<div class='location-map__bubble-wrapper'><div class='location-map__bubble-title'><a target='_blank' href='" +  feature.getProperty('link') + "'>" + feature.getProperty('title') + "</a></div>";
                    html += "<div class='location-map__bubble-desc'>" + feature.getProperty('description') + "</div>";
                    html += "<div class='location-map__bubble-img'><a target='_blank' href='" +  feature.getProperty('link') + "'><img src='" + feature.getProperty('image') + "' /></a></div>";
                    html += "<div class='location-map__bubble-address'>" + feature.getProperty('address') + '</div>';
                    html += "<div class='location-map__bubble-directions'><a href='https://www.google.com/maps/dir/?api=1&destination=" + g.lat + "," + g.lng + "' target='_blank'>Get Directions</a></div></div>";
                    infowindow.setContent(html);
                    infowindow.setPosition(event.latLng);
                    infowindow.setOptions({
                        pixelOffset: new google.maps.Size(0, -34)
                    });
                    infowindow.open(map);
                });

                oms.addMarker(marker);

                return marker;
            });

            markerCluster = updateMarkerCluster(map, markers, markerCluster);
            doubleCheckMarkerList(markerList);
        });

        cycleways.loadGeoJson(cycleWaySrc);
        dogAreas.loadGeoJson(dogAreaSrc);

        cycleways.setMap(map);
        dogAreas.setMap(map);

        cycleways.setStyle({
            strokeColor: '#c12c92',
            fillColor: '#c12c92',
        });

        dogAreas.setStyle({
            strokeColor: "#d6ad00",
            fillColor: "#d6ad00",
        });

        if(defaultFilterList.length){
    
            setTimeout(function(){
                for(var i = 0; i < catList.length; i++){
                    
                    if(defaultFilterList.indexOf(catList[i]) == -1){

                        if (catList[i] != "dogs" && catList[i] != "cycleways"){
                            setMarkerHidden(catList[i]);
                        }else {
                            setPolyHidden(catList[i]);
                        }

                        $('input[type="checkbox"][value="' + catList[i] + '"]').prop('checked',false);                        

                    }
                }
            },1000);
        }

    }

    function updateMarkerCluster(map, markers, mCluster) {

        mCluster = new MarkerClusterer(map, markers, {
            imagePath: 'https://cdn.rawgit.com/googlemaps/js-marker-clusterer/gh-pages/images/m',
        });

        var minClusterZoom = 15;
        mCluster.setMaxZoom(minClusterZoom);

        return mCluster;
    }

    function addToMarkerList(category, marker, markerList) {
        switch (category) {

            case "Art & Culture Sites":
                markerList["arts"].push(marker);
                break;

            case "Beaches & Pools":
                markerList["beaches"].push(marker);
                break;

            case "Car Parks":
                markerList["carparks"].push(marker);
                break;

            case "Community Centres":
                markerList["communities"].push(marker);
                break;

            case "Events":
                markerList["events"].push(marker);
                break;

            case "Libraries":
                markerList["libraries"].push(marker);
                break;

            case "Parks & Gardens":
                markerList["parks"].push(marker);
                break;

            case "Playgrounds":
                markerList["playgrounds"].push(marker);
                break;

            case "Skate Parks":
                markerList["skate"].push(marker);
                break;

            case "Sportsgrounds & Facilities":
                markerList["sports"].push(marker);
                break;

            case "Works & Projects":
                markerList["works"].push(marker);
                break;
        }
    }


    function getMarkerIcon(category) {

        var icon = "";

        switch (category) {

            case "Art & Culture Sites":
                icon = '//wollongong.nsw.gov.au/_nocache?a=3838:static/mysource_files/icon-map-arts-culture.png';
                break;

            case "Beaches & Pools":
                icon = '//wollongong.nsw.gov.au/_nocache?a=3838:static/mysource_files/icon-map-beach-pool.png';
                break;

            case "Car Parks":
                icon = '//wollongong.nsw.gov.au/_nocache?a=3838:static/mysource_files/icon-map-carpark.png';
                break;

            case "Community Centres":
                icon = '//wollongong.nsw.gov.au/_nocache?a=3838:static/mysource_files/icon-map-community.png';
                break;

            case "Events":
                icon = '//wollongong.nsw.gov.au/_nocache?a=3838:static/mysource_files/icon-map-events.png';
                break;

            case "Libraries":
                icon = '//wollongong.nsw.gov.au/_nocache?a=3838:static/mysource_files/icon-map-library.png';
                break;

            case "Parks & Gardens":
                icon = '//wollongong.nsw.gov.au/_nocache?a=3838:static/mysource_files/icon-map-park.png';
                break;

            case "Playgrounds":
                icon = '//wollongong.nsw.gov.au/_nocache?a=3838:static/mysource_files/icon-map-playground.png';
                break;

            case "Skate Parks":
                icon = '//wollongong.nsw.gov.au/_nocache?a=3838:static/mysource_files/icon-map-skatepark.png';
                break;

            case "Sportsgrounds & Facilities":
                icon = '//wollongong.nsw.gov.au/_nocache?a=3838:static/mysource_files/icon-map-sports.png';
                break;

            case "Works & Projects":
                icon = '//wollongong.nsw.gov.au/_nocache?a=3838:static/mysource_files/icon-map-projects.png';
                break;

            case "Cycleways":
                icon = '//wollongong.nsw.gov.au/_nocache?a=3838:static/mysource_files/icon-map-cycleways.png';
                break;

            case "Dogs of Leash Areas":
                icon = '//wollongong.nsw.gov.au/_nocache?a=3838:static/mysource_files/icon-map-dogs.png';
                break;

        }

        return icon;
    }


    /*
        Search autocomplete
    */

    function autoCompleteInit(map) {

        var $locationInput = $('.location-map').find('input#location-map__search')[0],
            infowindow = new google.maps.InfoWindow(),
            infowindowContent = $('.location-map').find('#infowindow-content')[0],
            autocomplete = new google.maps.places.Autocomplete($locationInput),
            marker = new google.maps.Marker({
                map: map,
                anchorPoint: new google.maps.Point(0, -29),
                animation: google.maps.Animation.DROP,
                icon: 'https://wollongong.nsw.gov.au/_nocache?a=3838:static/mysource_files/icon-map-locate.png'
            });

        infowindow.setContent(infowindowContent);
        autocomplete.bindTo('bounds', map);

        autocomplete.addListener('place_changed', function () {

            infowindow.close();
            marker.setVisible(false);
            var place = autocomplete.getPlace();
            if (!place.geometry) {
                window.alert("No details available for input: '" + place.name + "'");
                return;
            }

            // If the place has a geometry, then present it on a map.
            if (place.geometry.viewport) {
                map.fitBounds(place.geometry.viewport);
            } else {
                map.setCenter(place.geometry.location);
                map.setZoom(17); // Why 17? Because it looks good.
            }
            marker.setPosition(place.geometry.location);
            marker.setVisible(true);

            var address = '';
            if (place.address_components) {
                address = [
                    (place.address_components[0] && place.address_components[0].short_name || ''),
                    (place.address_components[1] && place.address_components[1].short_name || ''),
                    (place.address_components[2] && place.address_components[2].short_name || '')
                ].join(' ');
            }

            infowindowContent.children['place-icon'].src = place.icon;
            infowindowContent.children['place-name'].textContent = place.name;
            infowindowContent.children['place-address'].textContent = address;
            infowindow.open(map, marker);
        });
    }

    /*
        Show user current location
    */

    function showUserLocation() {

        var $userlocationButton = $('.location-map').find('.location-map__location button');

        $userlocationButton.click(function (e) {
            e.preventDefault();

            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(showPosition, showError);
            }

        });
    }


    /*
        Error callback if user location is disabled or inaccessible
    */
    function showError() {

        if (!$('.location-map__location-note').is(':visible')) {
            $('.location-map__location-note').slideDown('fast');

            setTimeout(function () {
                $('.location-map__location-note').slideUp('fast');
            }, 7000);
        }
    }

    /*
        Show the location icon and move the map
    */
    function showPosition(position) {

        var userLocation = {
                lat: position.coords.latitude,
                lng: position.coords.longitude
            },
            userMarker = new google.maps.Marker({
                position: userLocation,
                map: map,
                animation: google.maps.Animation.DROP,
                icon: 'https://wollongong.nsw.gov.au/_nocache?a=3838:static/mysource_files/icon-map-locate.png'
            }),
            centerMapPos = new google.maps.LatLng(position.coords.latitude, position.coords.longitude);
        map.panTo(centerMapPos);

        return userMarker;
    }

    /*
        Initialise the filter toggle button
    */

    function toggleFilterListInit() {

        $('.location-map__toggle-wrap').find('button').click(function () {

            var $cur = $(this);

            if (!$cur.hasClass('all')) {
                $('.location-map__options').find('input[type="checkbox"]').prop('checked', true);
                $cur.addClass('all');
                $cur.text('Deselect all filters');

                for (var property in markerList) {
                    setMarkerVisible(property);
                }

                setPolyVisible('dogs');
                setPolyVisible('cycleways');

                doubleCheckMarkerList(markerList);
            } else {
                $cur.removeClass('all');
                $cur.text('Select all filters');
                $('.location-map__options').find('input[type="checkbox"]').prop('checked', false);

                for (var property in markerList) {
                    setMarkerHidden(property);
                }

                setPolyHidden('dogs');
                setPolyHidden('cycleways');

                doubleCheckMarkerList(markerList);
            }

        });
    }

    /*
        Initialise the filter buttons
    */
    function filterButtonInit() {


        $('.location-map__options li').each(function () {

            var $checkbox = $(this).find('input[type="checkbox"]');

            $checkbox.prop('checked',true);

            $checkbox.change(function () {

                var $cur = $(this),
                    cat = $cur.val();

                if (!$cur.is(':checked')) {
                    if (cat != "cycleways" && cat != "dogs") {
                        setMarkerHidden(cat);
                        updateToggleFilterList();
                    } else {
                        setPolyHidden(cat);

                        doubleCheckMarkerList(markerList);
                    }
                } else {
                    if (cat != "cycleways" && cat != "dogs") {
                        setMarkerVisible(cat);
                        updateToggleFilterList();
                    } else {
                        setPolyVisible(cat);

                        doubleCheckMarkerList(markerList);
                    }
                }
            });


        });
    }


    /*
        Create an array from the default list of options to show (if global variable exists in page source)
    */

    function getDefaultListArray(){

        var defaultFilterList = "";

        if(defaultFilters != undefined){
            defaultFilterList = defaultFilters.split(";");
            
            for(var i = 0; i < defaultFilterList.length; i++){
                if(defaultFilterList[i].length){
                    defaultFilterList[i] = defaultFilterList[i].trim(defaultFilterList[i]);
                }else {
                    defaultFilterList.splice(i);
                }
            }
        }

        return defaultFilterList;

    }


    /*
        Check toggle filter list and update if certain number of filters are checked/unchecked
    */
    function updateToggleFilterList() {
        var checked = $('.location-map__options').find('input[type="checkbox"]:checked'),
            unchecked = $('.location-map__options').find('input[type="checkbox"]:not(:checked)'),
            toggle = $('.location-map__toggle-wrap').find('button');

        if (checked.length > unchecked.length) {
            toggle.addClass('all');
            toggle.text('Deselect all filters');
        } else if (checked.length < unchecked.length) {
            toggle.removeClass('all');
            toggle.text('Select all filters');
        } else {
            // Leave even changes unchanged
        }
    }

    /*
        Toggle the visibility of the map markers given a category
    */

    function setMarkerVisible(category) {
        for (var i = 0; i < markerList[category].length; i++) {
            var tempMarker = markerList[category][i];
            markerCluster.addMarker(tempMarker);
        }
    }

    function setMarkerHidden(category) {
        for (var i = 0; i < markerList[category].length; i++) {
            var tempMarker = markerList[category][i];
            markerCluster.removeMarker(tempMarker);
        }
    }


    /*
        Toggle the visibility of the map polygons given a category
    */

    function setPolyVisible(category) {

        switch (category) {

            case "cycleways":
                cycleways.setMap(map);
                break;

            case "dogs":
                dogAreas.setMap(map);
                break;
        }

    }

    function setPolyHidden(category) {
        switch (category) {

            case "cycleways":
                cycleways.setMap(null);
                break;

            case "dogs":
                dogAreas.setMap(null);
                break;
        }
    }


    /*
        Initialise the map filter reduce button
    */

    function reduceFilterListInit() {

        $('.location-map').removeClass('location-map--no-reduce');

        $('.location-map__reduce-wrap').find('button').click(function () {

            if (!$('.location-map__options').hasClass('reduce')) {
                $('.location-map__options').addClass('reduce');
                $(this).text('Show more filters');
            } else {
                $('.location-map__options').removeClass('reduce');
                $(this).text('Show less filters');
            }

            return false;
        });

    }

    /*
        Double check that markers exist in filter for map, otherwise hide markers
    */

    function doubleCheckMarkerList(markerList) {
        var keys = Object.keys(markerList);
            
        var $filters = $('input[type="checkbox"][name^="location-map__filter-"]');

        $filters.each(function(index, elem) {
            var val = $(elem).val(),
                pop = keys.indexOf(val);

            if (pop >= 0) {
                keys.splice(pop,1);
            }
        });

        for (var i = 0; i < keys.length; i++) {
            setMarkerHidden(keys[i]);
            setPolyHidden(keys[i]);
        }
    }

}(jQuery));