/*global jQuery*/
/*global Cookies*/
/**
 * {{name}}
 * Global JS
 *
 * version: {{version}}
 * file:    {{file}}
 * author:  Squiz Australia
 * change log:
 *     {{date}} - First revision
 */

/*
 * Table of Contents
 *
 * - Global
 * - Modules
{{toc}}
 */
'use strict';

/*
--------------------
Global
--------------------
*/
//  Declare JS Enabled.
$('body').removeClass('no-js').addClass('js-enabled');


/*
--------------------
Modules
--------------------
*/


$('.content table').each(function() {
    var $table = $(this);
    if(
        !$table.hasClass('dynamic-table')
        && !$table.hasClass('mega-table')
        && !$table.hasClass('custom-table')
        && !$table.hasClass('loader_overlay')
    ) {
        $table.wrap('<div class="table"></div>');
    }
});

///////////////////////setting cookies and active user/////////////////////

//Functions for cookies on splash page and assigning body class on landing pages
var $splash = $('.js-splash'),
    $dropdownText = $('.splash__user-dropdown-text'),
    $dropdownTitle = $('.splash__user-dropdown-title'),
    userCookie = Cookies.get('wcc-user-type'),
    url = window.location.href;


if (userCookie && $splash.length && url) {

    switch (userCookie) {

        case "visitor":
            window.location.href = "/visitor-home";
            break;

        case "resident":
            window.location.href = "/resident-home";
            break;

        case "business":
            window.location.href = "/business-home";
            break;
    }

} else {

    if ($splash.length) {

        $splash.addClass('active');

        $('html,body').addClass('hideoverflow');

        $dropdownTitle.click(function () {
            var $cur = $(this);
            if (!$cur.hasClass('active')) {
                $cur.addClass('active');
                $dropdownText.slideDown("fast");
            } else {
                $cur.removeClass('active');
                $dropdownText.slideUp("fast");
            }
        });

        $splash.find('.splash__user-btns').find('a').each(function () {

            $(this).click(function () {

                var type = $(this).attr('data-user-type');
                Cookies.remove('wcc-user-type');

                if (type) {
                    setCookie(type);
                } else {
                    setCookie('resident');
                }

            });

        });

        $splash.find('.splash__close').click(function () {
            setCookie('resident');
            window.location.href = "/resident-home";
        });


    
    }
}

function setCookie(type) {
    Cookies.remove('wcc-user-type');
    Cookies.set('wcc-user-type', type, { expires: 1 });
}


//setting cookies for user type

var $setUser = $('.js-user-set'),
    $container = $('.js-search-container');

if($setUser.length) {
	setUserBtn();

	$setUser.find('.user-buttons__wrapper').find('a').each(function () {

	    $(this).click(function () {
	        var type = $(this).attr('data-user-type');

	        if (type) {
	            setCookie(type);
	        }
	    });
	});

	setActiveUser();

	var $mainLogo = $('.header-main__logo').children('a');

	$mainLogo.click(function (e) {

	    var userType = Cookies.get('wcc-user-type');

	    if (userType) {
	        e.preventDefault();
	        window.location.replace(window.location.origin + "/" + userType + "-home");
	    } else {
	        window.location.replace(window.location.origin + "/splash");
	    }
	});	
}

function setActiveUser() {
    var userType = Cookies.get('wcc-user-type');
    $container.find('a[data-user-type="' + userType + '"]').addClass('active');

    setActiveNavItems('.js-nav-active', '.main__mobile-secondary');
    setActiveNavItems('.js-nav-active', '.nav__mobile-secondary');
    setActiveNavItems('.js-secondary-active', '.main__mobile-secondary');
    setActiveNavItems('.js-secondary-active', '.nav__mobile-secondary');
    setActiveNavItems('.js-sub-nav', '.sub-nav__item');
}



//Setting active user buttons and nav items 

function setUserBtn() {
    var $visitorBtn = $('a[data-user-type="visitor"]');
    var $businessBtn = $('a[data-user-type="business"]');
    var $residentBtn = $('a[data-user-type="resident"]');

    $residentBtn.removeClass('active');
    $visitorBtn.removeClass('active');
    $businessBtn.removeClass('active');


    if ($('body').hasClass('resident-home')) {
        $residentBtn.addClass('active');


    } else if ($('body').hasClass('visitor-home')) {
        $visitorBtn.addClass('active');

    } else if ($('body').hasClass('business-home')) {
        $businessBtn.addClass('active');

    }
}

function setActiveNavItems(navContainer, liParentClass) {

    var $navMenu = $(navContainer),
        $navItems = $navMenu.find(liParentClass),
        activeUserType = $('.user-buttons').find('a.active').attr('data-user-type');

    $navItems.each(function () {
        var $curr = $(this);
        var userType = $curr.attr('data-user');
        userType = userType.replace(/\s/g, '');
        var userTypeArr = userType.split(";"),
            indexUserType = userTypeArr.indexOf(activeUserType);

        if (indexUserType >= 0) {
            $curr.addClass('active');
        }

    });

}






//dropdown megamenu - only float last one left if more than one

var active = $('.nav--secondary').find('.nav__mobile-secondary.active');
var activeLength = active.length;

if (activeLength > 1) {

    var lastActiveIndex = activeLength - 1;

    var lastActive = $('.nav--secondary').find('.nav__mobile-secondary.active').eq(lastActiveIndex).find('.nav__level-2-wrapper');

    //if lastActive.length returns true give it class of 'last-ul'

    if (lastActive.length) {
        lastActive.addClass('last-ul');
    }
}




// event listing page results summary

(function($){
  const $item = $('.event-list__item-wrap .event-list__item'),
  totalresult = parseInt($('.event-list .list-count').attr('data-result')),
  firstIndex = parseInt($item.first().attr('data-index')) + 1 ,
  lastIndex  = parseInt($item.last().attr('data-index')) + 1 ;

  const $text = $('.event-list .list-count__text');


  if( lastIndex < totalresult ) {
    $text.text('Showing ' + firstIndex + ' - ' + lastIndex + ' of ' + totalresult );
  } else {
    $text.text('Showing ' +  lastIndex + ' of '+ totalresult  );
  }
}(jQuery));


// Document Icons for links 
(function($){

    'use strict'

    $("a[href$='.jpg'], a[href$='.JPG'], a[href$='.png'], a[href$='.PNG']").not("table a[href$='.jpg'], table a[href$='.JPG'], table a[href$='.png'], table a[href$='.PNG']").addClass('document-icon__icon');
    $("a[href$='.pdf'], a[href$='.PDF']").not("table a[href$='.pdf'], table a[href$='.PDF']").addClass('document-icon__icon document-icon__icon--pdf'); 
    $("a[href$='.doc'], a[href$='.docx'], a[href$='.DOC'], a[href$='.DOCX']").not("table a[href$='.doc'], table a[href$='.docx'], table a[href$='.DOCX'], table a[href$='.DOC']").addClass('document-icon__icon document-icon__icon--word'); 
    $("a[href$='.ppt'], a[href$='.pptx'], a[href$='.PPTX'], a[href$='.PPT']").not("table a[href$='.ppt'], table a[href$='.pptx'], table a[href$='.PPTX'], table a[href$='.PPT']").addClass('document-icon__icon document-icon__icon--ppt'); 
    $("a[href$='.xls'], a[href$='.xlsx'], a[href$='.XLSX'], a[href$='.XLS']").not("table a[href$='.xls'], table a[href$='.xlsx'], table a[href$='.XLSX'], table a[href$='.XLS']").addClass('document-icon__icon document-icon__icon--excel'); 

}(jQuery)); 

//Depending on document link - what icon should display in table 
(function($){

    'use strict'
    
        $("table a[href$='.pdf'], table a[href$='.PDF']").parent().siblings(".table__icon").addClass('table__icon--pdf');
        $("table a[href$='.doc'], table a[href$='.docx'], table a[href$='.DOCX'], table a[href$='.DOC']").parent().siblings(".table__icon").addClass('table__icon--word');
        $("table a[href$='.ppt'], table a[href$='.pptx'], table a[href$='.PPTX'], table a[href$='.PPT']").parent().siblings(".table__icon").addClass('table__icon--ppt');
        $("table a[href$='.xls'], table a[href$='.xlsx'], table a[href$='.XLS'], table a[href$='.XLSX']").parent().siblings(".table__icon").addClass('table__icon--excel');

}(jQuery));