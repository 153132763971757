(function($){
    
    'use strict';

    var $dropdown = $('.js-dropdown'),
        speed = 500;

    $dropdown.each(function(){
        
        var $curDropdown = $(this),
            $wrapper = $curDropdown.find('.dropdown__list-wrapper'),
            $innerContain = $curDropdown.find('.dropdown__list-inner'),
            $showAllButton = $curDropdown.find('.js-show-all-button');

            $curDropdown.find('.dropdown__title').click(function(event){
                
                event.preventDefault();

                if(!$wrapper.hasClass('active')){
                    $wrapper.addClass('active');
                    $(this).addClass('active');
                    $innerContain.slideDown(speed);
                    
                }else {
                    $wrapper.removeClass('active');
                    $(this).removeClass('active');
                    $innerContain.slideUp(speed);
                }

            });

            if($showAllButton.length){

                $showAllButton.click(function(event){
                    event.preventDefault();

                    if(!$wrapper.hasClass('expanded')){
                        $wrapper.addClass('expanded');
                    }
                });
            }

    });

}(jQuery));